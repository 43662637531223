import React from "react"

import styles from "./sms.module.scss"
import { Helmet } from "react-helmet/es/Helmet"

const SMS = () => {

  return(
    <>
      <section className={styles.module}>
        <div className="klaviyo-form-SRFtJR" />
      </section>

      <Helmet>
        <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=cfVfSd" />
      </Helmet>
    </>
  )
}

export default SMS
